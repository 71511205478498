import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import useMatchMedia from '@/hooks/useMatchMedia';

type LayoutContextType = {
  isReady: boolean;
  isDarkMode: boolean;
  setDarkMode: (next: boolean) => void;
  isNavDocked: boolean;
  setNavDocked: (next: boolean) => void;
};

const context = createContext<LayoutContextType>({
  isReady: false,
  isDarkMode: false,
  setDarkMode: () => {},
  isNavDocked: false,
  setNavDocked: () => {},
});

const { Provider } = context;
export const useLayoutContext = () => useContext(context);

const LayoutContext: FC<PropsWithChildren> = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [isNavDocked, setNavDocked] = useState(false);
  const [userPrefersDarkMode, setUserPrefersDarkMode] = useState(
    useMatchMedia('(prefers-color-scheme: dark)')
  );

  const setDarkMode = (next: boolean) => {
    window.localStorage.setItem('userPrefersDarkMode', String(next));
    setUserPrefersDarkMode(next);
  };

  useEffect(() => {
    setUserPrefersDarkMode(
      localStorage.getItem('userPrefersDarkMode') === 'true'
    );
    setIsReady(true);
  }, []);

  return (
    <Provider
      value={{
        isReady,
        isDarkMode: userPrefersDarkMode,
        setDarkMode,
        isNavDocked,
        setNavDocked,
      }}
    >
      {children}
    </Provider>
  );
};

export default LayoutContext;
