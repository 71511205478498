import { useEffect, useState } from 'react';

const useMatchMedia = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const handleResize = () => setMatches(window.matchMedia(query).matches);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return matches;
};

export default useMatchMedia;
