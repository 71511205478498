exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-index-tsx": () => import("./../../../src/pages/newsletter/index.tsx" /* webpackChunkName: "component---src-pages-newsletter-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-articles-index-tsx": () => import("./../../../src/templates/articles/index.tsx" /* webpackChunkName: "component---src-templates-articles-index-tsx" */),
  "component---src-templates-author-articles-index-tsx": () => import("./../../../src/templates/authorArticles/index.tsx" /* webpackChunkName: "component---src-templates-author-articles-index-tsx" */),
  "component---src-templates-author-index-tsx": () => import("./../../../src/templates/author/index.tsx" /* webpackChunkName: "component---src-templates-author-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-category-index-tsx": () => import("./../../../src/templates/category/index.tsx" /* webpackChunkName: "component---src-templates-category-index-tsx" */),
  "component---src-templates-docs-index-tsx": () => import("./../../../src/templates/docs/index.tsx" /* webpackChunkName: "component---src-templates-docs-index-tsx" */),
  "component---src-templates-tag-index-tsx": () => import("./../../../src/templates/tag/index.tsx" /* webpackChunkName: "component---src-templates-tag-index-tsx" */)
}

